/* common style */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital@0;1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  background-color: #e5e5e5;
}

@media only screen and (max-width: 767px) {
  body {
    min-width: 320px;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none !important;
  color: unset !important;
  transition: 0.2s;
}

a:hover {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
  margin: 0px;
}

img {
  display: block;
  width: 100%;
  transition: 0.2s;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.h100 {
  height: 100%;
}

.container {
  /* custum */
  max-width: 1170px;
  margin: 0 auto;
}

:root {
  --title-color: #0055ab;
  --btn-primary-ct: #003f8e;
  --btn-danger-ct: #f24a4a;
  --btn--success-ct: #1ba466;
  --text-color: #383838;
  --text-color-second: #5f5f5f;
}

.only-pc {
  display: block;
}

.only-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .only-pc {
    display: none;
  }

  .only-sp {
    display: block;
  }
}

/* ==========header========== */
header {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #003f8e;
}

.header__inner {
  background-image: url(../image/header-bg.png);
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header__logo {
  padding-left: 15px;
}

.header__logo img {
  max-width: 50px;
}

.header__logo .site-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.header__admin {
  padding-right: 28px;
  cursor: pointer;
  position: relative;
}

.header__admin img.avatar {
  width: 26px;
}

.admin__text p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px 6px 0px 8px;
}

.admin__text img {
  width: 8px;
}

.admin-popup {
  position: absolute;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  z-index: 10;
  top: 43px;
  right: 0;
  padding: 7px 0px;
  display: none;
}

.admin-popup.is__open {
  display: block;
}

.admin-popup li {
  padding: 9px 15px;
}

.admin-popup li a,
.admin-popup li .logout {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  white-space: nowrap;
}

.admin-popup li a:hover,
.admin-popup li .logout:hover {
  color: var(--title-color);
}

.admin-popup li a img {
  width: 14px;
  margin-right: 10px;
}

.admin-popup li .logout img {
  width: 14px;
  margin-right: 10px;
}
/* ==========header========== */

.wrap-page {
  width: 100%;
  position: relative;
  height: auto;
  flex-wrap: nowrap;
  align-items: flex-start;
}

/* ======aside====== */
aside {
  background-color: #fff;
  min-width: 200px;
  width: 15%;
  max-width: 300px;
  height: calc(100vh - 60px);
  min-height: 600px;
  box-shadow: 2px 0px 6px 0px #00000026;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aside__menu {
  position: relative;
  width: 100%;
}

.aside__menu > li {
  position: relative;
}

.aside__menu > li .aside__menu-sub {
  background-color: #ecf7fe;
  transition: height 0.3s ease;
}

.aside__menu > li.is__open .aside__menu-sub {
  display: block;
}

.aside__menu-sub li:first-child {
  padding-top: 5px;
}
.aside__menu-sub li:last-child {
  padding-bottom: 5px;
}

.aside__menu-sub li a,
.aside__menu-sub li .btn-sidebar {
  display: block;
  font-weight: 400;
  padding: 8.5px 15px;
  font-size: 14px;
  color: var(--text-color-second);
  transition: 0.2s;
}

.aside__menu li a,
.aside__menu li .btn-sidebar {
  position: relative;
}

.aside__menu li a > span,
.aside__menu li .btn-sidebar > span {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #f24a4a;
  font-size: 11px;
  font-weight: 500;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.aside__menu-sub li a.is__active,
.aside__menu-sub li .btn-sidebar.is__active {
  font-weight: 500;
}

.aside__menu > li > a,
.aside__menu > li > .btn-sidebar {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 8.5px 15px;
  font-size: 14px;
  color: var(--text-color-second);
  cursor: pointer;
}

.aside__menu > li > a svg,
.aside__menu > li > .btn-sidebar svg {
  fill: #5f5f5f;
}

.aside__menu > li > a.is__active,
.aside__menu > li > .btn-sidebar.is__active {
  background-color: #359ae1;
  font-weight: 500;
  color: #fff;
}

.aside__menu > li > a.is__active svg,
.aside__menu > li > .btn-sidebar.is__active svg {
  fill: #fff;
}

.aside__menu-icon {
  width: 28px;
  display: flex;
  align-items: center;
}

.aside__menu > li > a > svg,
.aside__menu > li > .btn-sidebar > svg {
  max-width: 20px;
  width: auto;
  transition: 0.2s;
  fill: #5f5f5f;
}

.copyright {
  padding: 0 15px 20px;
}

.copyright p {
  font-size: 13px;
  color: var(--text-color);
  font-weight: 300;
  margin-top: 12px;
}

/* ======aside====== */

.page-content {
  flex: 1;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  padding-bottom: 30px;
}

/* ====== Login-style ====== */
.login-page {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background-color: #fff;
  padding: 55px 48px 60px;
  max-width: 465px;
  transition: 0.2s;
}

.login-box > h3 {
  color: var(--title-color);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.login-box form {
  margin-top: 45px;
}

.login-box form .form-group {
  margin-bottom: 22px;
}

.login-box form .form-group label {
  font-size: 16px;
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 6px;
}

.login-box form .form-group input {
  border-radius: 2px;
  padding: 8px 12px;
}

.f-confirm .form-group {
  width: 48%;
}

.confirm__code {
  margin-left: 20px;
  flex: 1;
}

.confirm__code button {
  background-color: var(--text-color);
  border-radius: unset;
  padding: 0px;
  height: 42px;
  line-height: 42px;
  min-width: 126px;
}

.confirm__code button span {
  font-size: 24px;
  color: #fff;
  display: inline-block;
  font-weight: 300;
  margin: 0 3px;
}

.confirm__code button span:nth-child(1) {
  transform: rotate(-15deg);
}

.confirm__code button span:nth-child(2) {
  transform: rotate3d(1, 1, 1, 20deg);
}

.confirm__code button span:nth-child(3) {
  transform: rotate3d(-1, -1, -1, -15deg);
}

.confirm__code img {
  max-width: 20px;
  margin-left: 17px;
  cursor: pointer;
}

.login-box form button[type="button"] {
  width: 100%;
  margin-top: 40px;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  background-color: var(--btn-primary-ct);
  padding: 10px;
  border: 1px solid var(--btn-primary-ct);
  transition: 0.2s;
}

.login-box form button[type="submit"]:hover {
  background-color: transparent;
  color: var(--title-color);
}

@media only screen and (max-width: 600px) {
  .login-box > h3 {
    font-size: 22px;
  }
  .login-page {
    padding: 15px;
    width: 100%;
  }
  .login-box {
    max-width: 100%;
    padding: 30px 15px 40px;
  }
  .login-box form {
    margin-top: 25px;
  }
  .login-box form .form-group input {
    padding: 6px 10px;
  }
  .login-box form .form-group label {
    font-size: 14px;
  }
  .confirm__code button {
    height: 38px;
    line-height: 38px;
    padding: 0px 20px;
    min-width: unset;
  }
  .confirm__code button span {
    font-size: 18px;
  }
  .login-box form button[type="submit"] {
    margin-top: 25px;
    padding: 6px 10px;
    font-size: 15px;
  }
}
/* ====== Login-style ====== */

.page__title {
  padding-bottom: 8px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
}

.page__title span {
  font-size: 16px;
  font-weight: 700;
  color: var(--title-color);
  position: relative;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
}

.page__title span::after {
  position: absolute;
  content: "";
  width: 100%;
  top: 104%;
  left: 0;
  height: 2px;
  background-color: var(--title-color);
}

.wrap__content-page {
  border-radius: 3px;
  margin-top: 22px;
  background-color: #fff;
  width: 100%;
  padding: 30px;
  border: 1px solid #eaeaea;
}

.form__save {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-ct {
  min-width: 138px;
  margin: 0px 15px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: 0.2s;
  min-height: 40px;
  color: #fff;
  padding: 6px 22px;
}

.btn-custom-ct {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: 0.2s;
  color: #fff;
  min-height: 40px;
}

.btn-ct:hover {
  opacity: 0.7;
}

.btn-default-ct {
  background-color: #dddddd;
  color: var(--text-color);
}

.btn-primary-ct {
  background-color: var(--btn-primary-ct);
}

.btn-danger-ct {
  background-color: var(--btn-danger-ct);
}

.btn-success-ct {
  background-color: var(--btn--success-ct);
}

.btn-back-ct {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-back-ct > img {
  width: 18px;
  margin-right: 3px;
}

.btn-back-bg {
  background-color: #359ae1;
  color: #fff;
}

.form-row {
  margin-right: -10px;
  margin-left: -10px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.form-control {
  padding: 9px 12px;
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 400;
  max-height: 40px;
}

.custom-select {
  background: #fff url(../image/down02.png) no-repeat right 11.5px center;
  background-size: 11.5px;
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 400;
  height: 40px;
}

.form-group > label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.form-group > label span {
  color: var(--btn-danger-ct);
}

/* table-common style */
.wrap-table {
  overflow-x: auto;
}

.wrap-table::-webkit-scrollbar {
  background-color: #ebebeb;
  height: 8px;
}

.wrap-table::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.wrap-table > h3 {
  font-size: 18px;
  color: var(--title-color);
  font-weight: 500;
  margin-bottom: 24px;
}

.wrap-table table {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eef0f2;
}

.wrap-table .table thead th {
  font-weight: 500;
  position: relative;
}

.wrap-table .table thead th img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20%;
  width: 9px;
}

.wrap-table .table th,
.wrap-table .table td {
  border-top: none;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
}

.table thead th,
.table td {
  vertical-align: middle;
}

.table td .table-action img {
  max-width: 18px;
  margin: 0px 9px;
}
/* table-common style */

/* =====pagination===== */
.pagination {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination > p {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 400;
}

.pagination button,
.pagination select {
  margin-left: 6px;
  border: 1px solid #cccccc;
  background-color: #fff;
  border-radius: 1px;
  height: 30px;
  cursor: pointer;
}

.pagination .disabled {
  pointer-events: none;
  opacity: 0.7;
}

.pagination button {
  width: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination button img {
  height: 10px;
  width: auto;
}

.pagination select {
  max-width: 60px;
}
/* =====end-pagination===== */

/* thông tin công trinh, dự án */
.info__project form .form-group {
  margin-bottom: 18px;
}

.info__project {
  margin-bottom: 20px;
}

.info__project > h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  margin-bottom: 22px;
}

.file__attach.wrap-table .table th,
.file__attach.wrap-table .table td {
  font-size: 16px;
  padding: 8px 12px;
  vertical-align: middle;
}

.table-input input {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.table__avatar {
  max-width: 58px;
  border-radius: 2px;
}

.table__avatar img {
  border-radius: 2px;
}

.wrap-table__user {
  margin-top: 30px;
}

.table__user.table td,
.table__user.table th {
  padding: 13px 20px;
  vertical-align: middle;
}

.message-list.table td,
.message-list.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.message-list.table tbody tr {
  background-color: #fff;
}

.add__row .btn-ct {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  min-height: unset;
  font-size: 16px;
  font-weight: 400;
  width: 120px;
  min-width: unset;
}

.add__row .btn-ct > img {
  width: 12px;
  margin-right: 4px;
}

.table-input {
  width: 100%;
  margin: 0 auto;
}

.table-input .btn-ct {
  max-height: 34px;
  min-height: unset;
  font-weight: 400;
  width: 120px;
  min-width: unset;
  margin: 0px;
}

.btn-ct > img {
  max-width: 24px;
  margin-right: 3px;
}

.btn-ct.btn-add > img {
  max-width: 18px;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 2px;
}

.btn-action {
  font-size: 14px;
  font-weight: 400;
  color: #ff9238 !important;
  padding: 4px 15px;
  border: 1px solid #ff9238;
  border-radius: 3px;
  background-color: #fff;
  transition: 0.2s;
  min-width: 85px;
}

.btn-action:hover {
  opacity: 0.7;
}

.btn-action.is__active {
  color: var(--btn--success-ct) !important;
  border-color: var(--btn--success-ct);
}

/* thông tin công trinh, dự án */

.select-ct-radius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-ct-border {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.form__save-ct {
  flex: 1;
}

.action__user > img {
  max-width: 18px;
  margin-right: 8px;
}

.action__user {
  cursor: pointer;
}

.table td .action__user button {
  margin-top: 3px;
  text-decoration: none;
  color: #359ae1;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

.table td .action__user a {
  margin-top: 3px;
  text-decoration: none;
  color: #359ae1;
}

.table td .action__user a.deleted {
  color: var(--btn-danger-ct);
}

/* modal */
.modal-dialog {
  max-width: 600px;
}

.modal-header {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--btn-primary-ct);
}

.modal-header > h5 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.modal-header button {
  outline: none;
}

.modal-header button.close {
  opacity: 1;
}

.modal-header button > img {
  width: 24px;
}

.modal-select-avatar > h4 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 10px;
}

.modal-select-avatar .inner {
  border: 1px solid #c0c1c4;
  border-radius: 3px;
  padding: 24px;
  text-align: center;
}

.modal-select-avatar .inner > h5 {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
}

.modal-select-avatar .inner > p {
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 5px;
}

.modal-select-avatar .inner > span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  margin-top: 15px;
}

.modal-select-avatar .img {
  width: 100px;
  position: relative;
  margin: 0 auto;
}

.modal-select-avatar .img button {
  position: absolute;
  border: none;
  outline: none;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 5px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.MuiInputAdornment-root {
  position: absolute;
  right: 25px;
}
.MuiInputAdornment-root:focus-visible,
.MuiInputAdornment-root:focus {
  outline: none;
}

.MuiTouchRipple-root:focus-visible {
  outline: none;
}
/* modal */

.ck-editor__editable_inline {
  min-height: 200px;
}

.file__block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20p;
  margin-bottom: 20px;
}

.file__block img {
  width: 100%;
}

.file__block .btn-delete {
  width: 24px;
  cursor: pointer;
}

.text_ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.text_ellipsis_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 1360px) {
  .form__save-ct {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .wrap__content-page {
    padding: 15px;
  }
  .form__save {
    flex-wrap: wrap;
  }
  .form__save button {
    margin-bottom: 20px;
  }
}
.icon {
  max-width: 18px;
  margin: 0px 9px;
  cursor: pointer;
}
.loading {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.loading .spinner {
  height: 20px;
  width: 20px;
}
.border-error-tr {
  border: 1.5px solid #d32f2f;
}
.form-section {
  background-color: #e8eef0;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 20px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.action-form-section {
  position: absolute !important;
  right: 28px;
  cursor: pointer;
  z-index: 2;
}

.async-select {
  width: 100%;
}
.single-value {
  font-size: 14px;
}
.invalid > div {
  border: 1px solid #d32f2fed;
}

.MuiOutlinedInput-root {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.MuiFormControl-root.is-invalid {
  border: 1px solid #dc3545;
}

.m-w-335 {
  min-width: 335px;
}

.full {
  width: 100%;
}

.disabled {
  pointer-events: none;
}

.disabled_scroll {
  overflow-y: unset !important;
}

.place_doc {
  max-height: 40vh !important;
  overflow: scroll;
}

.width_10 {
  width: 10%;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: 50% !important;
  height: auto !important;
}

.cover > .photo-viewer-container > img {
  width: 30% !important;
  height: auto !important;
}

.bold {
  font-weight: bold !important;
}
